import React from 'react';
import { Link } from 'react-router-dom';
import './EcoWayInfoPage.css'; // Ensure to create this CSS file

const EcoWayInfo = () => {
  return (
    <div className="ecoway-page">
      <div className="ecoway-header" style={{ backgroundImage: `url(${require('./ecoway_image.png')})` }}>
      </div>
      <div className="content">
        <section>
          <h2>About the Project</h2>
          <p>
            Participating in the 2024 Georgetown HoyaHacks Hackathon, we developed EcoWay, a solution aimed at reducing carbon footprints by providing users with eco-friendly transportation options. Our project incorporates advanced mapping and environmental data to offer the best routes for public transit, biking, and walking, alongside detailed carbon emission data.
          </p>
        </section>
        <section>
          <h2>Technologies Used</h2>
          <ul>
            <li>React for Frontend Development</li>
            <li>ExpressJS for Backend Server</li>
            <li>Google Maps API for Directions and Maps</li>
            <li>Environmental Impact APIs for Carbon Emission Data</li>
          </ul>
        </section>
        <section>
          <h2>My Role</h2>
          <p>
            As a Frontend Developer, I was responsible for designing the user interface, integrating the Google Maps API, and ensuring a seamless user experience that effectively communicates the environmental impact of different transportation modes.
          </p>
        </section>
        <section>
          <h2>The Challenge</h2>
          <p>
            The main challenge was to accurately calculate and display carbon emissions for each transportation option in real-time, encouraging users to make more environmentally friendly choices.
          </p>
        </section>
        <section>
          <h2>The Solution</h2>
          <p>
            Through our collaborative effort, we developed a user-friendly platform that not only provides efficient routing options but also educates users on their environmental impact, promoting a more sustainable lifestyle.
          </p>
        </section>
        <section>
          <h2>Demo</h2>
          <p>
            Unfortunately due to the cost of the Google API key, the backend does not work. So only the frontend works.
          </p>
        </section>

        <div className="call-to-action">
          <Link to="/"><button className="btn-home">Go to Home</button></Link>
          <a href="https://www.ecowayexpress.us/" target="_blank" rel="noopener noreferrer"><button className="btn-demo">Frontend Demo</button></a>
        </div>
      </div>
    </div>
  );
};

export default EcoWayInfo;
