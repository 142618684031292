import React from 'react';
import './App.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

// Assuming other prop types remain the same and adding buttonVariant
const Experience = ({ image, text, title, quote, sub, buttonVariant, Blabel, Blink}) => {
  return (
    <div className='container'>
      <img src={image} alt="description" className='image'/>
      <div className='smaller'>
        <p className='title'>{title}</p>
        <i className='subtitle'>{sub}</i>

        <i className='quote'>{quote}</i>
        <br></br>

        <p className='text'>{text}</p>
        {buttonVariant && <Button variant={buttonVariant} href={Blink}>{Blabel}</Button>}
      </div>
    </div>
  );
};

export default Experience;
