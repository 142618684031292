import React from 'react';
import { Link } from 'react-router-dom';
import './EcoWayInfoPage.css'; // Ensure to create this CSS file


const DeepGuardInfoPage = () => {
  return (
    <div className="ecoway-page">
      <div className="ecoway-header" style={{ backgroundImage: `url(${require('./deepguard.webp')})` }}>

      </div>
      <div className="content">
        <section>
          <h2>About the Project</h2>
          <p>
          DeepGuard is a novel solution to detecting deep fake take a multimodal approach to identifying and warning users about deepfakes. DeepGuard employs a novel detection algorithm that parses three pieces of crucial information from a Deep Fake video: Video display, Audio and speech analysis. By parsing this crucial information from a Deep Fake video, DeepGuard then runs a custom novel detection algorithm that breaks down information and tries to identify key features that might indicate that it is a Deep Fake.
          </p>
        </section>
        <section>
          <h2>How we built it </h2>
          <p>
          We built DeepGuard using a react front end combined with a FastAPI backend. Our novel detection algorithm was ran through various API's and ML algorithms. For voice detection or voice spoofing analysis we used a machine learning algorithm from the internet known as a Resemblyzer which is trained on a voice spoofing dataset and our own custom voice samples to identify fradulent or AI generated voices. For Video analysis, we used Arya.AI, a Deep Fake detection API catered specifically towards video analysis. Lastly for speech analysis we combined to API's plus a little bit of prompt engineering. We used google's Chirp Speech to text API to get the text of what was spoken in a DeepFake Video. We then fed that text to ChatGPT 4.0 API and asked it to cross reference that text by what was typically said by the subject in the DeepFake.
          </p>
         
        </section>
        <section>
          <h2>My Role</h2>
          <p>
            As a Developer, I was integral in building the core functionality of HealthWay, including the implementation of API integrations for location and weather data, and the development of the logic for generating personalized health advice.
          </p>
        </section>
        <section>
          <h2>The Challenge</h2>
          <p>
          Our biggest challenges we ran into was prompt engineering for ChatGPT to give us the custom data we wanted it to. We spent almost 12 hours creating various prompts for ChatGPT and Intel's Developer Cloud LLM to be able to take the text of a deepfake and compare to what was typically said by the subject in a DeepFake. The output was often not specific or confused about the desired outcome and the unpredictability of the LLMs made it hard to build a prompt that fed us consistent data.
          </p>
        </section>
        <section>
          <h2>Accomplishments that we're proud of</h2>
          <p>
          We are proud of the UI and implementing a completely new backend in python which we had never done before. We are also extremely proud of finally being able to integrating machine learning and LLMs into our project which we had struggled to do many times before but were finally able to accomplish it well during this project.
          </p>
        </section>
        <section>
          <h2>What we learned</h2>
          <p>
          We learned about prompt engineering and LLM's. We also learned a lot about using various API's and custom machine learning algorithms along with FastAPI. On the frontend we learned a lot about various react elements and creating creative UI's.
          </p>
        </section>
        <div className="call-to-action">
          <Link to="/"><button className="btn-home">Go to Home</button></Link>
          <a href="https://devpost.com/software/deepguard" target="_blank" rel="noopener noreferrer"><button className="btn-demo">Our Devpost</button></a>

        </div>
      </div>
    </div>
  );
};

export default DeepGuardInfoPage;
