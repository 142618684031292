import React, { useState, useEffect } from 'react';
import { useSpring, animated, } from '@react-spring/web';
import Experience from './Experience';
import Journey from './Journey';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import droneImg from './bigMe.JPG';
import ktt3 from './ktt3.png';
import gryphon_logo from './gryphone_image.png';
import jump_logo from './jump_logoreal.png';
import ecoway_image from './ecoway_image.png';
import healthway_image from './healthway_image.png';
import gh from './github.png';
import autodog_image from './autodog_image.png';
import resumeIcon from './resumeicon.png';
import DeepGuardImage from './DeepGuardImage.png';
import lin from './ln.png';
import './App.css';

const HomePage = () => {
  const fade = useSpring({ to: { opacity: 1 }, from: { opacity: 0 }, delay: 500 });
  

  return (
    <div className="App">
      <div
        offset={0}
        speed={0.5}
        style={{ backgroundImage: `url(${droneImg})`, backgroundSize: 'cover', minHeight: '100vh' }}>
        <animated.div style={fade}>
          <div className="back">
            <i className='hey'>Hey, I'm</i>
            <h2 className='heys'>Nyan Prakash</h2>
          </div>
        </animated.div>
      </div>


      {/* Adjusted offset for Experience components */}
        <div className='App-header' >
        <br></br>

          {/* Experience component for KidsTeachTech */}
          <Journey />

          <Experience
            image={ktt3}
            title="KidsTeachTech"
            sub="Lead Teacher"
            text="For three years, I taught Java, C#, Python, and Scratch programming to over 500 underprivileged kids across the SF Bay Area as part of Kids Teach Tech.
             Our mission was to ignite creativity and curiosity in children who had never encountered programming before, encouraging them to delve deeper into it.
              My hope was for at least one of the kids I taught to develop an interest in computer science. 
            I aimed to inspire one of these kids to discover their passion for computer. I wanted to be the reason they fell in love with programming."
            quote="The meaning of life is to find your gift.  The purpose of life is to give it away -Pablo Picasso"

            Blabel="Learn More"
            buttonVariant="danger"
            Blink='https://kidsteachtech.com/'
          />

        <br></br>
        <br></br>
        <br></br>

        <Experience
            image={autodog_image}
            title="AutoDog"
            sub="Developer"
            text="When my friend decided to develop an automated dog training harness, I immediately chose to get involved. 
            I'm applying my skills in robotics, electronics, and programming to turn this dream product into reality!
             We are using Arduino as well as a variety of sensors to detect what position the dog. We are creating a curriculum for novice dog trainers to follow.
              Our goal is to make dog training fun and easy.
             It has been an amazing experience to put my skills towards a product. "
            quote="I have not failed I've just found 10,000 ways that won't work -Thomas Edison"
            buttonVariant='primary'
            Blabel="Learn More"
            Blink="https://sites.google.com/view/autodog-dev"
          />




<br></br>
        <br></br>
        <br></br>

        <Experience
            image={gryphon_logo}
            title="Gryphon Robotics"
            sub="Programming Captain"
            text="After fixing a critical bug in the heat of competition, I became programming captain my first year. As programming captain my role was to keep the team going by teaching the newer members how to program 
            so they can take over as captain one day. I dedicated my time to assisting them with debugging their code and ensuring that we were on track to complete our programming tasks before the competition."
            quote="Friends are the thieves of time -Francis Bacon"
            buttonVariant= "success"
            Blabel="Learn More"
            Blink='https://www.frc5549.org/'
          /> 

<br></br>
        <br></br>
        <br></br>

        <Experience
            image={jump_logo}
            title="Jump Robotics"
            sub="Founder"
            text= "After getting rejected from my high school robotics team, 7 of my friends and I decided, we still wanted to do robotics. We decided that we were going to start our own robotics team. Because of we were such a small team, I learned had to take up many different roles. I learned Java programming, electrical, and how to lead a team. I remember nights where we were working at 3am in my friends garage to get our robot working.  I loved being around a team that was as motiviated and driven as I was."
            quote="It ain't about how hard you can hit. Its about how hard you can get hit and keep moving forward - Rocky Balboa"

            buttonVariant='primary'
            Blabel='Learn More'
            Blink='https://www.jumprobotics.org/'
          />           
          
        <br></br>
        <br></br>
        <br></br>
          
          <Experience
        image={ecoway_image}
        text="I participated in the 2024 GeorgeTown HoyaHacks Hackathon, and it was an awesome experience. Our project was named EcoWay.
         We built a website using React for the front end and ExpressJS for the server side. To figure out directions, we incorporated the Google Maps API.
          We also used a different API to get information on public transit, traffic, and biking. 
        The software calculates and shows the carbon emissions for each type of transportation and also integrates Google Maps."
        quote="Everything negative - pressure, challenges - is all an opportunity for me to rise. -Kobe Bryant"
        title="EcoWay"
        sub="Frontend Developer"
        buttonVariant='success'
        Blabel='Learn More'
        Blink='/#/ecoway-info'
        />


<br></br>
        <br></br>
        <br></br>

      <Experience
        image={healthway_image}
        text="My high school friends and I took part in the yearly Carnegie Mellon University Hackathon. We developed HealthWay, a personal health advisory report. 
        The app uses your existing health conditions and location to provide tailored advice.
         It also translates place names into their longitude and latitude coordinates. 
         Next, it retrieves information from a weather API to show weather updates and offers personalized recommendations based on your health conditions.
        "
        quote="Talent wins games, but teamwork and intelligence win championships. – Michael Jordan"
        title="Healthway"
        sub="Developer"

        buttonVariant='success'
        Blabel='Learn More'
        Blink='/#/healthway-info'
        />

        <br></br>
        <br></br>
        <br></br>

      <Experience
        image={DeepGuardImage}
        text="I took part in the University of Virginia’s annual Hoohacks 2024 Hackathon with my friends Shaunak Sinha, Brennan Pan, and Mateo Aguilar. 
        Over 24 hours, we created a tool that spots deepfakes and fake voices. Our system checks videos from three angles—text, voice, and video itself—to 
        spot fakes more accurately. It uses the OpenAI API to compare what’s said in the video against what’s likely to be said by the person, a machine learning model 
        to catch voice changes, and a special API to determine if a video is real or edited. This combination lets us reliably identify fake videos.
        "
        quote="If you're not getting better, you're getting worse. -Draymond Green"
        title="DeepGuard"
        sub="Developer"

        buttonVariant='primary'
        Blabel='Learn More'
        Blink='/#/deepguard-info'
        />



<br></br>
        <br></br>
        <br></br><br></br>
        <br></br>
        </div>

       
        <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} className='App-footer'>

<a href="https://github.com/Nyan-Prakash" target="_blank" rel="noopener noreferrer" class="footer-link" style={{ textDecoration: 'none' }}>
<img className='' src={gh} alt="GitHub Profile" style={{ width: '150px', height: '150px' }} />
</a>

<a href="https://www.linkedin.com/in/nyan-prakash/" target="_blank" rel="noopener noreferrer" class="footer-link" style={{ textDecoration: 'none' }}>
<img src={lin} alt="LinkedIn Profile" style={{ width: '115px', height: '115px' }} />
</a>

<a href="https://nyanprakash.com/resume.pdf" target="_blank" rel="noopener noreferrer" class="-link" style={{ textDecoration: 'none' }}>
<img src={resumeIcon} alt="Resume PDF" style={{ width: '150px', height: '150px'}} />
</a>


</div>
      
      </div>

     
  );
};

export default HomePage;
