import React from 'react';
import { Link } from 'react-router-dom';
import './EcoWayInfoPage.css'; // Ensure to create this CSS file

const HealthWayInfoPage = () => {
  return (
    <div className="ecoway-page">
      <div className="ecoway-header" style={{ backgroundImage: `url(${require('./healthwaybg.webp')})` }}>

      </div>
      <div className="content">
        <section>
          <h2>About the Project</h2>
          <p>
            HealthWay was developed during the annual Carnegie Mellon University Hackathon, where my team aimed to enhance personal health management through technology. Our application, HealthWay, offers personalized health advice based on users' health conditions and geographical locations, integrating real-time weather updates to provide comprehensive health recommendations. I built HealthWay with my friends Shaunak Sinha and Brennan Pan.
          </p>
        </section>
        <section>
          <h2>Technologies Used</h2>
          <ul>
            <li>Location APIs for Geographical Data</li>
            <li>Weather APIs for Real-time Climate Information</li>
            <li>Custom Algorithms for Health Advice Generation</li>
          </ul>
        </section>
        <section>
          <h2>My Role</h2>
          <p>
            As a Developer, I was integral in building the core functionality of HealthWay, including the implementation of API integrations for location and weather data, and the development of the logic for generating personalized health advice.
          </p>
        </section>
        <section>
          <h2>The Challenge</h2>
          <p>
            The primary challenge was to seamlessly integrate diverse data sources to provide accurate and actionable health advice tailored to each user's specific needs and environmental conditions.
          </p>
        </section>
        <section>
          <h2>The Solution</h2>
          <p>
            Through innovative coding and design, we created a user-friendly platform that not only aids users in managing their health more effectively but also educates them on the importance of considering environmental factors in their health decisions.
          </p>
        </section>
        <section>
          <h2>Demo</h2>
          <p>
            Unfortunately due to the cost of the Google Maps and weather API, the backend does not work. So only the frontend works.
          </p>
        </section>
        <div className="call-to-action">
          <Link to="/"><button className="btn-home">Go to Home</button></Link>
          <a href="https://health-way-git-master-nyan-prakashs-projects.vercel.app/" target="_blank" rel="noopener noreferrer"><button className="btn-demo">View Frontend Demo</button></a>
        </div>
      </div>
    </div>
  );
};

export default HealthWayInfoPage;
