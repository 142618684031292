import React from 'react';
import './App.css';

// Removed the interface Props definition

const Journey = () => {
  return (
    <div>
      <h1 style={{textAlign:'center'}}>My Journey</h1>
      <p1>I am a computer science student at Northern Virginia Community College. I love building cool technology with people!</p1>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default Journey;
